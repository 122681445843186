.App {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

label, button, div {
    -webkit-tap-highlight-color: transparent;
}