.record-list {
    height: calc(95vh - 100px);
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &::-webkit-scrollbar {
        display: none;
    }
}

.record-item {
    max-width: 436px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #333;
    line-height: 24px;
    text-align: justify;
    font-style: normal;
    text-transform: none;
    box-sizing: border-box;
    margin-bottom: 24px;
    position: relative;
    display: inline-block;
}

.item-content {
    padding: 16px 24px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(4px);

    & .timeState {
        margin-top: 16px;
    }
}

.user {
    align-self: self-end;

    & .item-content {
        color: #ffffff;
        border-radius: 10px;
        background: linear-gradient(135deg, #0aa7ff 0%, #2d81ff 100%);

        & .timeState {
            margin-top: 0;
        }
    }
}

.request-status {
    width: 82px;
    height: 24px;
    background: #ffffff;
    border-radius: 3px;
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 12px;
    color: #626973;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    text-indent: 10px;
    position: relative;
    border: 0;

    &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 8px;
        transform: translateY(-50%);
        width: 6px;
        height: 6px;
        background: #60bc23;
        border-radius: 6px;
    }
}

.tag {
    height: 24px;
    border-radius: 3px;
    padding: 0 8px;
    font-weight: 400;
    font-size: 12px;
    color: rgb(165, 88, 201);
    text-align: center;
    font-style: normal;
    text-transform: none;
    border: 1px solid rgb(238, 204, 255);
    display: inline-block;
    box-sizing: border-box;
    line-height: 22px;
    margin-right: 8px;

    & b {
        font-weight: 400;
    }
}

.template {
    width: 436px;
    margin-top: 0;
}

.tips {
    font-weight: 600;
    font-size: 20px;
    color: #333333;
    line-height: 28px;
    font-style: normal;
    text-transform: none;
    margin-bottom: 16px;
}

.template-item {
    width: 380px;
    font-weight: 500;
    font-size: 14px;
    color: #135cca;
    line-height: 22px;
    font-style: normal;
    text-transform: none;
    text-indent: 15px;
    margin-bottom: 8px;
    cursor: pointer;

    &:last-child {
        margin-bottom: 0;
    }
}

.vertical {
    & .record-list {
        height: 82.6%;
        padding: 0 20px;
        margin-bottom: 70px;
        overflow: hidden scroll;
    }

    & .record-item  {
        max-width: 72%;
        font-size: 12px;
        line-height: 20px;
    }

    & .template {
        width: 200px;
    }

    & .template-item {
        width: 100%;
        font-size: 12px;
        margin-bottom: 4px;
    }

    & .item-content {
        padding: 8px 12px;
    }

    & .tips {
        font-size: 14px;
        margin-bottom: 8px;
    }

    & .timeState {
        margin-top: 8px;
    }

    & .tag {
        padding: 0 4px;
    }
}

@media only screen and (max-width: 600px) {
    .record-list {
        height: 50vh;
        padding: 0 20px;
        margin-bottom: 70px;
        overflow: hidden scroll;
    }

    .record-item  {
        max-width: 72%;
        font-size: 12px;
        line-height: 20px;
    }

    .template {
        width: 200px;
    }

    .template-item {
        width: 100%;
        font-size: 12px;
        margin-bottom: 4px;
    }

    .item-content {
        padding: 8px 12px;
    }

    .tips {
        font-size: 14px;
        margin-bottom: 8px;
    }

    .timeState {
        margin-top: 8px;
    }

    .tag {
        padding: 0 4px;
    }
}