.card-item {
    width: 132px;
    height: 154px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    background-color: #f6f7f9;
    cursor: pointer;
}

.card-item-active {
    border-color: #1873fd;
}

.card-text {
    width: 42px;
    height: 24px;
    font-weight: 500;
    font-size: 14px;
    color: #5a5a5a;
    line-height: 24px;
    text-align: center;
    font-style: normal;
    text-transform: none;
    margin-top: 10px;
}
