.button {
    color: #959ca6;
    font-weight: 400;
    font-size: 16px;
    line-height: 54px;
    font-style: normal;
    text-transform: none;
    border-radius: 16px 16px 16px 16px;
    transition: all 500ms ease;
    background-position: center;
}

.press-status {
    box-shadow: 0 0 5px rgba(255, 255, 255, 1);
    background-color: rgba(255, 255, 255, 1);
    position: relative;
    
    &::after {
        content: '';
        position: absolute;
        top: -54px;
        left: 50%;
        transform: translateX(-50%);
        width: 192px;
        height: 22px;
        background: url('../../assets/sound-wave-effect.gif') center / cover;
        opacity: 0;
        animation: fadeIn 1s ease-in-out 500ms forwards;
    }
}

@keyframes fadeIn {
    to {
       opacity: 1;
    }
}

.vertical {
    .press-status::after {
        top: -36px;
    }
}

@media only screen and (max-width: 600px) {
    .press-status::after {
        top: -36px;
    }
}
