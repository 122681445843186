.page-digital {
    width: 100vw;
    height: 100vh;
    font-family: PingFang SC, PingFang SC;
    position: relative;
    /* background-image: linear-gradient(135deg, #e6f3ff, #c4ddff); */
    background-color: #cce2f3;
    overflow: hidden;
}

.page-container {
    width: 74%;
    height: 100%;
    min-width: 1280px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.character-area {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;;

    & #liveVideo {
        width: calc(100vh * 1080 / 1920);
        height: 100vh;
    }
    & #liveVideo > div {
        background-color: transparent !important;
    }
}

.main-area {
    position: absolute;
    bottom: 0;
    right: 104px;
    width: 648px;
    height: 95vh;
}

/* .content-area {} */

.input-area {
    position: absolute;
    /* bottom: 44px;
    right: 0; */
    width: 70%;
    height: 54px;
    display: flex;
    justify-content: space-between;
    top: 50%;
    left: -75%;
    right: auto;

    & > * {
        border-radius: 16px;
        box-shadow: 3px 3px 13px rgba(0, 0, 0, 0.2);
    }

    & img {
        cursor: pointer;
    }

    & .input-mode {
        width: 308px;
        height: 100%;
        text-align: center;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 16px;
        box-sizing: border-box;
        cursor: pointer;
        user-select: none;
    }

    & .input-box {
        display: flex;
        align-items: center;
    }
}

/* 右上角区域 */
.setting-area {
    position: fixed;
    top: 16px;
    right: 16px;
    display: flex;
    flex-direction: column;

    & img {
        cursor: pointer;
    }
}

.ant-tabs .ant-tabs-nav::before {
    border-bottom: none !important;
}

.ant-tabs-tab-btn {
    font-size: 16px;
    font-weight: 400;
    color: #626973;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #333333 !important;
    font-weight: 500;
}

.ant-spin-blur::after {
    opacity: 0 !important;
}

.ant-modal {
    top: 64px;
    right: 16px;
    margin: 0;
    transform: none;
    position: absolute;
}

.vertical {
    width: 375px;
    height: 667px;

    & img {
        -webkit-tap-highlight-color: transparent;
    }
    
    & .page-container {
        width: 100%;
        height: 100%;
        min-width: unset;
    }

    & .character-area {
        width: 100%;
        height: 100%;

        & img {
            object-fit: cover;
        }

        & #liveVideo {
            width: 100%;
            height: 667px;
        }
    }

    & .content-area {
        width: 100%;
        height: 60%;
    }

    & .main-area {
        width: 100%;
        height: 100%;
        left: 0;
        display: flex;
        flex-direction: column-reverse;

        & .input-area {
            bottom: calc(20px + constant(safe-area-inset-bottom));
            bottom: calc(20px + env(safe-area-inset-bottom));
            transform: scale(0.85);
        }

        & .input-mode {
            width: 60%;
            backdrop-filter: blur(4px);
        }
    }

    & .setting-area {
        position: absolute;
        top: 0;
        right: 5px;
        transform: scale(0.8);
    }

    & .ant-modal {
        top: 47px;
        right: 9px;
        width: 95% !important;
        height: 80% !important;
    }

    & .ant-modal-wrap {
        position: absolute;
    }

}

/* 针对手机 */
@media only screen and (max-width: 600px) {
    .page-container {
        width: 100vw;
        height: 100vh;
        min-width: unset;
    }

    img {
        user-select: none;
        -webkit-touch-callout: none;
        -webkit-tap-highlight-color: transparent;
    }

    .character-area {
        width: 100vw;
        height: 100vh;
    }

    .character-area img {
        object-fit: cover;
    }
    
    #liveVideo {
        width: calc(100vh * 1080 / 1920);
        height: 100vh;
    }

    .main-area {
        width: 100vw;
        height: 100vh;
        left: 0;
        display: flex;
        flex-direction: column-reverse;
    }
    .input-area {
        bottom: calc(20px + constant(safe-area-inset-bottom));
        bottom: calc(20px + env(safe-area-inset-bottom));
        transform: scale(0.85);
    }

    .input-area .input-mode {
        width: 60%;
        user-select: none;
        backdrop-filter: blur(4px);
        height: 100%;
        text-align: center;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 16px;
        box-sizing: border-box;
    }

    .main-area > div {
        -webkit-tap-highlight-color: transparent;
    }

    .setting-area {
        top: 0;
        right: 5px;
        transform: scale(0.8);
    }

    .ant-modal {
        top: 47px;
        right: 9px;
        margin: 0 !important;
    }
}
