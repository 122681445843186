.login-wrapper {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cce1f2;
}

.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 500px;
    height: 300px;
    margin-top: -100px;
    background-color: #fff;
    border-radius: 30px;
}

.login-title {
    margin-top: 30px;
    margin-bottom: 30px;
}

/* .login-body {} */

.login-input {
    margin-bottom: 30px;
}

.submit {
    margin: auto;
    display: block;
}

/* 针对手机 */
@media only screen and (max-width: 600px) {
    .login-container {
        width: 60%;
        padding: 0 36px;
    }
}